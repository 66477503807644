import React, { useEffect, useState } from "react";
import { Row, Col, Image, Stack } from "react-bootstrap";
import addIcon from "assets/images/addIcon.svg";
import "assets/styles/common.scss";
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/CategoryManagementDetails/categoryManagementDetails.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CustomPopover from "components/common/CutomPopover";
import ToastNotification from "components/common/Toast";
import CommonModal from "components/common/Modal";
import LabelWithInput from "components/common/Input/LabelWithInput";
import crossIcon from "assets/images/crossIcon.svg";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import CheckSubjectValidation from "components/feature/Teacher/TeacherDetails/validation";
import { getProductManagementCallAPI } from "store/AdminDashboard/GetProductManagement/action";
import { GET_PRODUCT_MANAGEMENT } from "store/AdminDashboard/GetProductManagement/constant";
import { addProductManagementCallAPI } from "store/AdminDashboard/AddProductManagement/action";
import { ADD_PRODUCT_MANAGEMENT } from "store/AdminDashboard/AddProductManagement/constant";
import SwitchInput from "components/common/Input/SwitchInput";
import { UPDATE_PRODUCT_MANAGEMENT } from "store/AdminDashboard/UpdateProductManagement/constant";
import { updateProductManagementCallAPI } from "store/AdminDashboard/UpdateProductManagement/action";

const ProductManagementDetails = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [conf, setConf] = ToastNotification();
  const [anchorEl, setAnchorEl] = useState(null);

  const productManagementList = useSelector(
    (state) => state?.getProductManagementReducer?.payload?.data
  );
  const [viewData, setViewData] = useState("");
  const [isProductsError, setIsProductsError] = useState(false);
  const [isProductAddModalActive, setIsProductAddModalActive] = useState(false);
  const [editProduct, setEditProduct] = useState(false);

  const [formAddProductValue, setFormAddProductValue] = useState({
    productName: "",
    productDescription: "",
    productDuration: "",
    productPrice: "",
  });

  const handlePopoverClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setViewData(item);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const PopoverData = [
    {
      label: <FormattedMessage id="Edit" />,
      icon: (
        <EditOutlinedIcon style={{ marginRight: "5px", marginTop: "-5px" }} />
      ),
      onClick: () => {
        editProductHandle();
      },
    },
  ];

  useEffect(() => {
    getProductManagementLists();
  }, []);

  const getProductManagementLists = () => {
    dispatch(
      getProductManagementCallAPI(
        GET_PRODUCT_MANAGEMENT,
        {},
        { run: true, navigate: navigate }
      )
    );
  };

  const editProductHandle = () => {
    setEditProduct(true);
    setIsProductAddModalActive(true);
    setFormAddProductValue((prev) => ({
      ...prev,
      ["productName"]: viewData?.name,
      ["productDescription"]: viewData?.description,
      ["productDuration"]: viewData?.duration,
      ["productPrice"]: viewData?.price,
    }));
  };

  const handleProductUpdateSuccess = (res) => {
    setConf({
      msg: "Product Update Successfully !",
      variant: "success",
    });
    getProductManagementLists();
  };

  const handleProductSuccess = (res) => {
    setConf({
      msg: "Product Added Successfully !",
      variant: "success",
    });
    getProductManagementLists();
  };

  const handleProductError = (res) => {
    setConf({
      msg: res?.data?.duration?.[0],
      variant: "error",
    });
  };

  const handleAddSubjects = () => {
    setEditProduct(false);
    setIsProductsError(false);
    setIsProductAddModalActive(true);
    setFormAddProductValue((prev) => ({
      ...prev,
      ["productName"]: "",
      ["productDescription"]: "",
      ["productDuration"]: "",
      ["productPrice"]: "",
    }));
  };

  const handleAddProductSubmit = () => {
    const error = CheckSubjectValidation(formAddProductValue);
    setIsProductsError(error);
    if (error.submit) {
      let body = {
        name: formAddProductValue?.productName,
        description: formAddProductValue?.productDescription,
        duration: formAddProductValue?.productDuration,
        price: formAddProductValue?.productPrice,
      };
      dispatch(
        addProductManagementCallAPI(ADD_PRODUCT_MANAGEMENT, body, {
          run: true,
          handleFunction: (res) => handleProductSuccess(res),
          navigate: navigate,
          toast: true,
          handleToastError: (res) => handleProductError(res),
        })
      );
      setIsProductAddModalActive(false);
      setAnchorEl(null);
    }
  };

  const handleEditProductSubmit = () => {
    const error = CheckSubjectValidation(formAddProductValue);
    setIsProductsError(error);
    if (error.submit) {
      let body = {
        name: formAddProductValue?.productName,
        description: formAddProductValue?.productDescription,
        duration: formAddProductValue?.productDuration,
        price: formAddProductValue?.productPrice,
      };
      dispatch(
        updateProductManagementCallAPI(UPDATE_PRODUCT_MANAGEMENT, body, {
          productId: viewData?.id,
          run: true,
          handleFunction: (res) => handleProductUpdateSuccess(res),
          navigate: navigate,
          toast: true,
          handleToastError: (res) => handleProductError(res),
        })
      );
      setIsProductAddModalActive(false);
      setAnchorEl(null);
    }
  };

  const handleAddProductChange = (e) => {
    const { name, value } = e?.target;
    setFormAddProductValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCloseProductModel = () => {
    setIsProductAddModalActive(false);
    setAnchorEl(null);
  };

  const handleSwitchChange = (event, item) => {
    let body = {
      name: item?.productName,
      description: item?.productDescription,
      duration: item?.productDuration,
      price: item?.productPrice,
      active: item?.active === 1 ? 0 : 1,
    };
    dispatch(
      updateProductManagementCallAPI(UPDATE_PRODUCT_MANAGEMENT, body, {
        productId: item?.id,
        run: true,
        handleFunction: (res) => handleProductUpdateSuccess(res),
        navigate: navigate,
        toast: true,
        handleToastError: (res) => handleProductError(res),
      })
    );
  };

  return (
    <Row className="mt-5 mt-lg-0 pb-0 m-0">
      <h5 className="heading5 mt-2 mt-lg-0 p-0">
        <FormattedMessage id="ProductManagement" />
      </h5>

      <Row className="page-categoryManagement-contents mt-3 m-0 p-0 ps-2 pe-2">
        <Row className="p-0 m-0">
          <div className="flex justify-between items-center mt-2">
            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
              <FormattedMessage id="Products" />
            </p>
            <Stack
              direction="horizontal"
              gap={2}
              className="addSubjectButton flex justify-end"
              onClick={() => handleAddSubjects()}
            >
              <Image
                src={addIcon}
                alt={"icon"}
                style={{ height: "24px", width: "24px" }}
              />
              <p className="mb-0 font-semibold">
                <FormattedMessage id="Add Product" />
              </p>
            </Stack>
          </div>
        </Row>

        <Row className="p-0 m-0 mt-3 mb-3">
          <Row className="parent-student-list ps-3">
            <Col md={3} className="align-center text-start">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Product Name" />
              </p>
            </Col>
            <Col md={2} className="align-center text-start">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Price" />
              </p>
            </Col>
            <Col md={2} className="align-center text-center">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Duration" />
              </p>
            </Col>
            <Col md={3} className="align-center text-center">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Status" />
              </p>
            </Col>
            <Col md={2} className="align-center text-center">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Actions" />
              </p>
            </Col>
          </Row>

          {productManagementList?.length > 0 &&
            productManagementList.map((item, index) => (
              <Row className="parent-student-list ps-3">
                <Col md={3} className="align-center text-start">
                  <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">
                    {item?.name}
                  </p>
                </Col>

                <Col md={2} className="align-center text-start">
                  <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">
                    {item?.price}
                  </p>
                </Col>
                <Col md={2} className="align-center text-center">
                  <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">
                    {item?.duration}
                  </p>
                </Col>
                <Col md={3} className="align-center text-center">
                  <SwitchInput
                    label={false}
                    checked={item?.active}
                    onChange={(e) => handleSwitchChange(e, item)}
                    color="secondary"
                  />
                </Col>
                <Col md={2} className="align-center text-center">
                  <MoreHorizIcon
                    aria-describedby={id}
                    style={{ fill: "#3C1053", cursor: "pointer" }}
                    onClick={(e) => handlePopoverClick(e, item)}
                  />
                  <CustomPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handlePopoverClose}
                    options={PopoverData}
                  />
                </Col>
              </Row>
            ))}
        </Row>
      </Row>

      {/* Add Product Modal  */}
      <CommonModal
        padding={"32px 48px"}
        width={"736px"}
        modalActive={isProductAddModalActive}
        handleClose={() => handleCloseProductModel()}
        innerContent={
          <Box>
            <Row>
              <Col xs={12} md={12}>
                <div className="flex justify-between">
                  <h2 className="heading2">
                    {editProduct ? (
                      <FormattedMessage id="Edit Product" />
                    ) : (
                      <FormattedMessage id="Add Product" />
                    )}
                  </h2>
                  <Image
                    src={crossIcon}
                    alt={"icon"}
                    style={{ cursor: "pointer", height: "32px", width: "32px" }}
                    onClick={() => handleCloseProductModel()}
                  />
                </div>
              </Col>

              <Col xs={12} md={12} className="mt-2">
                <LabelWithInput
                  label={<FormattedMessage id="Product Name" />}
                  placeholder={intl.formatMessage({
                    id: "product Name",
                  })}
                  name="productName"
                  type="text"
                  value={formAddProductValue?.productName}
                  onChange={(e) => handleAddProductChange(e)}
                  errorText={
                    isProductsError &&
                    isProductsError?.productName?.error &&
                    isProductsError?.productName?.errorMessage
                  }
                />
              </Col>

              <Col xs={12} md={6} className="mt-2">
                <LabelWithInput
                  label={<FormattedMessage id="Duration" />}
                  placeholder={intl.formatMessage({
                    id: "Duration",
                  })}
                  name="productDuration"
                  type="number"
                  value={formAddProductValue?.productDuration}
                  onChange={(e) => handleAddProductChange(e)}
                  errorText={
                    isProductsError &&
                    isProductsError?.productDuration?.error &&
                    isProductsError?.productDuration?.errorMessage
                  }
                />
              </Col>

              <Col xs={12} md={6} className="mt-2">
                <LabelWithInput
                  label={<FormattedMessage id="Price" />}
                  placeholder={intl.formatMessage({
                    id: "Price",
                  })}
                  name="productPrice"
                  type="number"
                  value={formAddProductValue?.productPrice}
                  onChange={(e) => handleAddProductChange(e)}
                  errorText={
                    isProductsError &&
                    isProductsError?.productPrice?.error &&
                    isProductsError?.productPrice?.errorMessage
                  }
                />
              </Col>

              <Col xs={12} md={12} className="mt-2">
                <LabelWithInput
                  label={<FormattedMessage id="Description" />}
                  placeholder={intl.formatMessage({
                    id: "Description",
                  })}
                  height="150"
                  multiline
                  name="productDescription"
                  type="text"
                  value={formAddProductValue?.productDescription}
                  onChange={(e) => handleAddProductChange(e)}
                  errorText={
                    isProductsError &&
                    isProductsError?.productDescription?.error &&
                    isProductsError?.productDescription?.errorMessage
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6} md={6} className="">
                <Button
                  variant="outlined"
                  className="cancelButton mt-5 md:mt-2 w-100"
                  onClick={() => handleCloseProductModel()}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Col>
              <Col xs={6} md={6} className="">
                <Button
                  variant="contained"
                  className="button mt-5 md:mt-2 w-100"
                  onClick={() =>
                    editProduct
                      ? handleEditProductSubmit()
                      : handleAddProductSubmit()
                  }
                >
                  {editProduct ? (
                    <FormattedMessage id="Save" />
                  ) : (
                    <FormattedMessage id="Add Product" />
                  )}
                </Button>
              </Col>
            </Row>
          </Box>
        }
      />
      {/* Add Product Modal  */}
    </Row>
  );
};

export default ProductManagementDetails;
