import React, { useEffect, useState } from "react";
import { Row, Col, Image, Stack } from "react-bootstrap";
import "assets/styles/common.scss";
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/CategoryManagementDetails/categoryManagementDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastNotification from "components/common/Toast";
import Button from "@mui/material/Button";
import { getProductManagementCallAPI } from "store/AdminDashboard/GetProductManagement/action";
import { GET_PRODUCT_MANAGEMENT } from "store/AdminDashboard/GetProductManagement/constant";
import SwitchInput from "components/common/Input/SwitchInput";
import { UPDATE_PRODUCT_MANAGEMENT } from "store/AdminDashboard/UpdateProductManagement/constant";
import { updateProductManagementCallAPI } from "store/AdminDashboard/UpdateProductManagement/action";
import axios from "axios";
import LocalStorageManager from "managers/LocalStorageManger";

const ProductTeacherDetails = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [conf, setConf] = ToastNotification();

  const productManagementList = useSelector(
    (state) => state?.getProductManagementReducer?.payload?.data
  );

  useEffect(() => {
    getProductManagementLists();
  }, []);

  const getProductManagementLists = () => {
    dispatch(
      getProductManagementCallAPI(
        GET_PRODUCT_MANAGEMENT,
        {},
        { run: true, navigate: navigate }
      )
    );
  };

  const handleProductUpdateSuccess = (res) => {
    setConf({
      msg: "Product Update Successfully !",
      variant: "success",
    });
    getProductManagementLists();
  };

  const handleProductError = (res) => {
    setConf({
      msg: res?.data?.duration?.[0],
      variant: "error",
    });
  };

  const handleSwitchChange = (event, item) => {
    let body = {
      name: item?.productName,
      description: item?.productDescription,
      duration: item?.productDuration,
      price: item?.productPrice,
      active: item?.active === 1 ? 0 : 1,
    };
    dispatch(
      updateProductManagementCallAPI(UPDATE_PRODUCT_MANAGEMENT, body, {
        productId: item?.id,
        run: true,
        handleFunction: (res) => handleProductUpdateSuccess(res),
        navigate: navigate,
        toast: true,
        handleToastError: (res) => handleProductError(res),
      })
    );
  };

  const getToken = () => {
    const token = LocalStorageManager.getItem("token");
    return token;
  };

  const handlePaymentIntentCallApi = async (product) => {
    try {
      // Request signed CyberSource payment data
      const response = await axios.post(
        "https://lmsedu-api.theclientdemos.com/api/payment/initiate",
        { product_id: String(product?.id) },
        {
          headers: {
            Authorization: "Bearer" + " " + getToken(),
            "Content-Type": "application/json",
          },
        }
      );
      const { redirect_url, form_data } = response.data;

      // Create a form and auto-submit it
      const form = document.createElement("form");
      form.method = "POST";
      form.action = redirect_url;
      // Append hidden fields for CyberSource
      Object.entries(form_data).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
      console.log(form);
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error("Payment initiation failed", error);
    }
    // setLoading(false);
  };

  return (
    <Row className="mt-5 mt-lg-0 pb-0 m-0">
      <h5 className="heading5 mt-2 mt-lg-0 p-0">
        <FormattedMessage id="Products" />
      </h5>

      <Row className="page-categoryManagement-contents mt-3 m-0 p-0 ps-2 pe-2">
        <Row className="p-0 m-0 mt-3 mb-3">
          <Row className="parent-student-list ps-3">
            <Col md={2} className="align-center text-start">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Product Name" />
              </p>
            </Col>
            <Col md={4} className="align-center text-start">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Description" />
              </p>
            </Col>
            <Col md={2} className="align-center text-start">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Duration" />
              </p>
            </Col>
            <Col md={2} className="align-center text-center">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Price" />
              </p>
            </Col>

            <Col md={2} className="align-center text-center">
              <p className="m-0 fs-14 fw-400 fc-60656C ff-InterRegular">
                <FormattedMessage id="Actions" />
              </p>
            </Col>
          </Row>

          {productManagementList?.length > 0 &&
            productManagementList.map((item, index) => (
              <Row className="parent-student-list ps-3">
                <Col md={2} className="align-center text-start">
                  <p className="m-0 fs-16 fw-500 fc-1E1E1E ff-InterMedium">
                    {item?.name}
                  </p>
                </Col>

                <Col md={4} className="align-center text-start">
                  <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular truncate">
                    {item?.description}
                  </p>
                </Col>

                <Col md={2} className="align-center text-start">
                  <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">
                    {item?.duration}
                  </p>
                </Col>

                <Col md={2} className="align-center text-center">
                  <p className="m-0 fs-14 fw-400 fc-A2A2A2 ff-InterRegular">
                    {item?.price}
                  </p>
                </Col>

                <Col md={2} className="align-center text-center">
                  <Button
                    variant="contained"
                    className=""
                    style={{ height: "40px !important" }}
                    onClick={() => handlePaymentIntentCallApi(item)}
                  >
                    <FormattedMessage id="Buy Now" />
                  </Button>
                </Col>
              </Row>
            ))}
        </Row>
      </Row>
    </Row>
  );
};

export default ProductTeacherDetails;
