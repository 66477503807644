import { ADD_PRODUCT_MANAGEMENT } from "./constant";

export const addProductManagementCallAPI = (type, data, external = {}) => {
  switch (type) {
    case ADD_PRODUCT_MANAGEMENT:
      return {
        type: type,
        data: data,
        external: external,
      };
    default:
      return {
        type: type,
      };
  }
};
