import { FormattedMessage } from "react-intl";

const CheckValidation = (value) => {
  const formError = {
    submit: true,
    gradeLevel: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    language: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    experience: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    subject: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    subjectNameEn: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    subjectNameAr: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    gradeNameEn: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    gradeNameAr: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    productName: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    productDescription: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    productDuration: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
    productPrice: {
      error: false,
      errorMessage: <FormattedMessage id="fieldisrequired!" />,
    },
  };

  const emptyKeys = [];
  for (let key in value) {
    emptyKeys.push(key);
  }

  for (let i = 0; i < emptyKeys.length; i++) {
    if (!value[emptyKeys[i]]) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
    }
  }
  return formError;
};

export default CheckValidation;
