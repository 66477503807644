import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "assets/styles/common.scss";
import ProductTeacherDetails from "components/feature/Teacher/ProductDetails";

const TeacherProduct = () => {
  return (
    <Container fluid className="dashboard_container">
      <Row>
        <Col lg={2}>{/* Empty space on the left */}</Col>
        <Col lg={9} className="mt-lg-5 mb-lg-3">
          <ProductTeacherDetails />
        </Col>
        <Col lg={1}>{/* Empty space on the right */}</Col>
      </Row>
    </Container>
  );
};

export default TeacherProduct;
