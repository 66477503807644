import {
    GET_PRODUCT_MANAGEMENT,
    GET_PRODUCT_MANAGEMENT_ERROR,
    GET_PRODUCT_MANAGEMENT_SUCCESS,
} from "./constant";

const getProductManagementReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PRODUCT_MANAGEMENT:
            return { ...state, loading: true, error: '' };
        case GET_PRODUCT_MANAGEMENT_ERROR:
            return { ...state, type: GET_PRODUCT_MANAGEMENT_ERROR, loading: false, error: action.payload };
        case GET_PRODUCT_MANAGEMENT_SUCCESS:
            return { ...state, type: GET_PRODUCT_MANAGEMENT_SUCCESS, loading: false, error: '', payload: action.payload };
        default:
            return state;
    }
};

export default getProductManagementReducer;